<template>
    <div  class="main-header">
        <a href="/" class="logo-home">
            <div class="app-logo">
                <img src="../../assets/logo.png" alt="logo">
                <span class="logo-title">版权社会服务体系</span>
            </div>
        </a>
        <div class="app-menu">
            <el-menu
                :default-active="$route.name"
                class="el-menu-demo"
                mode="horizontal"
                :router="true"
                @select="handleSelect"
                text-color="#fff"
                active-text-color="#fff"

                >
                <el-menu-item index="home">
                    <a href="#/home">首页</a>
                </el-menu-item>
                <el-menu-item index="register">
                    <a href="#/register">版权登记</a>
                </el-menu-item>
                <!-- <el-menu-item index="query">
                    <a href="#/query">版权查询</a>
                </el-menu-item> -->
                <el-menu-item index="mediate">
                    <a href="#/mediate">版权调解</a>
                </el-menu-item>
                <el-menu-item index="study">
                    <a href="#/study">版权学习</a>
                </el-menu-item>
                <el-menu-item index="activity">
                    <a href="#/activity">版权活动</a>
                </el-menu-item>
            </el-menu>
        </div>
        <div class="user-option">
            <a href="http://home.bqshfw.com" target="_blank" class="login-btn">登录</a>
            <a href="http://home.bqshfw.com/#/register"  target="_blank" class="register-btn">注册</a>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                activiti:this.$route.name,
            }
        },
        mounted() {
            this.init();
            console.log(this.$route)
        },
        methods: {

            init(){

            },
            handleSelect(val){
                console.log(val,'---菜单--')

            },
            edit(){

            },
        },
        computed: {

        },
        watch: {

        }
    }
</script>

<style lang="less">

    .main-header{
        display: flex;
        // justify-content: space-between;
        align-items: center;
        width: 1200px;
        height: 68px;
        // padding: 0 50px;
        box-sizing: border-box;
        margin:0 auto;
        .logo-home{
            text-decoration: none;
            display: flex;
            align-items: center;
        }
        .el-menu.el-menu--horizontal{
            border:none;
            background-color: rgba(250,250,250,0);
        }
    
        .app-logo{
            display: flex;
            align-items: center;
            img{
                width: 39px;
                height: 39px;
            }
            .logo-title{
                font-weight: bold;
                font-family: Alibaba PuHuiTi;
                font-size: 16px;
                font-weight: 700;
                line-height: 21.95px;
                margin-left: 10px;
            }
        }
        .app-menu{
            margin-left: 56px;
            .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
                background-color: rgba(250,250,250,0);
                color: #fff!important;
            }
            .el-menu--horizontal>.el-menu-item.is-active{
                color: #fff !important;
                font-weight: 600;
                // border-bottom: none;
            }
            .el-menu-item{
                font-size: 16px;
                font-weight: 400;
                width: 120px;
                &:hover{
                    color: #fff;
                    background-color: fff;
                }
                a{
                    text-decoration: none;
                }
            }
            .el-menu--horizontal>.el-menu-item{
                height: 100%;
                line-height: 62px;
            }
            // .el-menu--horizontal>.el-menu-item:last-child{
            //     padding-right:0;
            // }
            .el-button {
                width: 112px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
            }
            .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
                margin-left:23px;
            }
        }
        .user-option{
            display: flex;
            align-items: center;
            .login-btn{
                width: 100px;
                font-size: 16px;
                line-height: 42px;
            }
            .register-btn{
                width: 100px;
                font-size: 16px;
                line-height: 42px;
                border: 1px solid #fff;
                border-radius: 23px;
            }
            .register-btn:hover,.login-btn:hover{
                border-color: #34C9FB !important;
                color: #34C9FB !important;
            }
        }
    } 
    .el-header{
        margin:0;
        padding: 0;
    }
    
 
</style>